.App {
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #F9FCFF;
  min-height: 100vh;
}

a {
  color: black;
  text-decoration: none;
}

a:visited {
  color: black;
}

a:hover {
  color: #3B509A;
}

b {
  color: #3B509A;
}

nav {
  padding: 6px;
  padding-top: 30px;
  margin-left: 15%;
  margin-right: 15%;
  /* background-color: #444e58; */
}

ul {
  list-style-type: none;
  font-size: 18px;
  text-align: left;
}

li {
  display: inline;
  margin-right: 10%;
}

.intro {
  /* background-color: rgb(56, 211, 59); */
  margin-top: 1%;
  margin-left: 15%;
  margin-right: 15%;
  min-height: 40vh;
  padding: 36px;
  display: flex;
  /* flex-wrap: wrap; */
  background-color: white;
  border-radius: 25px;
}

.childBox {
  /* background-color: bisque; */
  margin: 3%;
  padding: 4%;
  min-width: 35%;
  text-align: left;
  line-height: 28px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;;
}

.App-link {
  color: #61dafb;
}
